<!-- @format -->
<template>
  <b-card title="ATT经纪人图添加 请勿上传超过2M的图片">
    <validation-observer ref="createProdBrand">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="标题" label-cols="1" label-cols-md="3" content-cols="6"
                          content-cols-md="8"
            >
              <validation-provider #default="{ errors }" name="标题" rules="required">
                <b-form-input v-model="formData.title" :state="errors.length > 0 ? false : null"
                              placeholder="请输入标题"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="图片" label-cols="1" label-cols-md="3" content-cols="6"
                          content-cols-md="8"
            >
              <input type="file" placeholder="上传图片" ref="getImage" @change="changeInput">
              <img :src="formData.image" alt="加载失败"
                   style="width: 60px; height: 60px; border-radius: 50%; box-shadow: 0 0 5px #ccc; display: inline-block"
              >
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard, BFormTextarea } from 'bootstrap-vue'
import { reactive, ref, onUnmounted, getCurrentInstance, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import { required, url } from '@core/utils/validations/validations'
import WangEditor from '@/views/GeneralManagement/WebManager/Article/WangEditor.vue'

import store from '@/store'
import axiosIns from '@/libs/axios'
import ClassManagerModule from './BirthdayModule'
import axios from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'

export default {
  name: 'BrandCreate',
  components: {
    BCard,
    vSelect,
    WangEditor,
    BFormTextarea
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'birthday-module'
    const { router } = useRouter()
    const { proxy } = getCurrentInstance()
    const toast = useToast()
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ClassManagerModule)
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const isRequest = ref(false)
    const formData = reactive({
      title: '',
      image: '',
    })
    const createProdBrand = ref(null)

    const validationForm = () => {
      if (!formData.image) {
        toast.error('请上传图片')
        return false
      }
      isRequest.value = true
      createProdBrand.value
          .validate()
          .then(res => {
            if (res) {
              store.dispatch('birthday-module/createArticle', { ...formData })
                  .then(response => {
                    if (response.code === 0) {
                      proxy.$bvToast.toast('添加成功', {
                        title: '提示',
                        variant: 'success',
                        solid: true,
                      })
                      setTimeout(() => {
                        isRequest.value = false
                        router.back()
                      }, 2000)
                    } else {
                      proxy.$bvToast.toast(response.msg, {
                        title: '提示',
                        variant: 'success',
                        solid: true,
                      })
                    }
                  })
            } else {
              isRequest.value = false
            }
          })
          .catch(() => {
            isRequest.value = false
            console.log('validationForm error')
          })
    }

    // 获取文章分类
    const articleCate = ref([])

    const catePage = ref(1)
    const catePageSize = ref(10)
    const cateTotal = ref(0)
    const hasPrevpage = computed(() => catePage.value > 1)
    const hasNextpage = computed(() => catePage.value * catePageSize.value < cateTotal.value)
    const previmageRUL = ref('')
    const changeInput = (val = {}) => {
      const form = new FormData()
      form.append('image', val.target.files[0])
      previmageRUL.value = window.URL.createObjectURL(val.target.files[0])
      axios.post('/upload/uploadAvatar', form)
          .then(response => {
            if (response.code === -1) {
              toast.error(response.msg)
            } else {
              formData.image = process.env.VUE_APP_BASE_URL.replace('/backend', '/') + response.data.url
              console.log(formData.image)
            }
          })
          .catch(res => {
            toast.error(res.msg)
          })
    }

    return {
      validationForm,
      formData,
      createProdBrand,

      isRequest,
      required,
      url,
      catePage,
      articleCate,
      hasPrevpage,
      hasNextpage,
      previmageRUL,
      changeInput
    }
  },
}
</script>

<style scoped>
.style-chooser .vs__search::placeholder {
  color: #ccc;
}

.style-chooser .vs__dropdown-toggle {
  border: 1px solid red;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
</style>
